import {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  Dispatch,
  SetStateAction,
} from "react"
import validator from "validator"
import { TextField, MenuItem, Autocomplete, Checkbox, FormControlLabel } from "@mui/material"
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material"
import * as firestore from "firebase/firestore"

import { languageSelector as ls } from "@covvi/language-selector"
import { useAuth } from "@context/AuthContext"
import { ProfileData, ErrorObj, ButtonObj, UserObj } from "@typesFolder/types"
import { getProfile, getAuthDetails } from "@util/firebase/authFunctions"
import { getMyUsers } from "@util/firebase/userFunctions"
import CVSubtitle from "@ui/text/CVSubtitle"
import CVCardFooter from "@ui/sections/CVCardFooter"
import { ButtonRow } from "@ui/sections/ButtonRow"
import { stringDate } from "@util/commonFunctions/stringDate"
import HelpTextTooltip from "@ui/buttons/HelpTextTooltip"

const ROLE_OPTIONS: [string, string, string, string, string, string, string] = [
  "Admin",
  "Tech Team Member",
  "Customer Service Team Member",
  "Sales Team Member",
  "Distributor",
  "Clinician",
  "User",
]

interface Props {
  userData?: ProfileData
  section: "Account Management" | "Edit User" | "Add User"
  onSubmit?: (user?: ProfileData) => void
  additionalBtns?: ButtonObj[]
  primaryLoading?: boolean
  setHasChanges?: Dispatch<SetStateAction<boolean>>
}
const UserCard = forwardRef(function (
  { onSubmit, section, userData, additionalBtns, primaryLoading, setHasChanges }: Props,
  ref
) {
  const { profile } = useAuth()
  const isCovvi = [
    "Sales Team Member",
    "Customer Service Team Member",
    "Tech Team Member",
    "Admin",
  ].includes(profile!.role)
  const [disabled, setDisabled] = useState<boolean>(true)

  const [role, setRole] = useState<string>("")
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [emailAddress, setEmailAddress] = useState<string>("")
  const [clinicianEmail, setClinicianEmail] = useState<string>(
    profile?.role === "Clinician" ? profile?.email_address : ""
  )
  const [distributorEmail, setDistributorEmail] = useState<string>(
    profile?.role === "Distributor" ? profile?.email_address : ""
  )
  const [businessName, setBusinessName] = useState<string>("")
  const [contactNumber, setContactNumber] = useState<string>("")
  const [addressLine1, setAddressLine1] = useState<string>("")
  const [addressLine2, setAddressLine2] = useState<string>("")
  const [city, setCity] = useState<string>("")
  const [county, setCounty] = useState<string>("")
  const [stateProvinceTerritory, setStateProvinceTerritory] = useState<string>("")
  const [country, setCountry] = useState<string>("")
  const [postCode, setPostCode] = useState<string>("")
  const [emailVerified, setEmailVerified] = useState(false)
  const [hasResetPassword, setHasResetPassword] = useState(false)
  const [hasDataConsent, setHasDataConsent] = useState<boolean>()
  const [invitedBy, setInvitedBy] = useState<string>("")
  const [accountCreated, setAccountCreated] = useState<Date>()
  const [lastAuthLogIn, setAuthLastLogIn] = useState<Date | "None">("None")
  const [lastAppLogIn, setLastAppLogIn] = useState<Date | "N/A">("N/A")
  const [clinicianList, setClinicianList] = useState<ProfileData[] | undefined>([])
  const [distributorList, setDistributorList] = useState<ProfileData[] | undefined>([])
  const [covviCertDate, setCovviCertDate] = useState<firestore.Timestamp | "None">("None")
  const [covviCertAccreditor, setCovviCertAccreditor] = useState<string>("None")
  const [isRestricted, setIsRestricted] = useState<boolean>(false)

  useImperativeHandle(
    ref,
    () => {
      return {
        getData: () => {
          if (section === "Account Management") {
            return
          } else {
            let isValid = validateForm()
            if (isValid === false) {
              profile?.role === "Admin" && console.log("ERROR VALIDATING FORM")
            } else {
              if (profile) {
                const newUserData: UserObj = {
                  first_name: firstName,
                  last_name: lastName,
                  email_address: emailAddress,
                  business_name: businessName,
                  contact_number: contactNumber,
                  address_line_1: addressLine1,
                  address_line_2: addressLine2,
                  address_city: city,
                  address_county: county,
                  address_state_province_territory: stateProvinceTerritory,
                  address_country: country,
                  address_postal_code: postCode,
                  role,
                  isRestricted,
                }

                if (role === "User") {
                  if (clinicianEmail) {
                    const clinician = clinicianList?.filter(
                      (clinician) => clinician.email_address === clinicianEmail
                    )[0]?.uid
                    const distributor = distributorList?.filter(
                      (distributor) => distributor.email_address === clinicianEmail
                    )[0]?.uid
                    newUserData.clinician = clinician || distributor || ""
                  } else {
                    newUserData.clinician = ""
                  }
                }
                if (role === "Clinician") {
                  if (covviCertDate !== "None") {
                    newUserData.covvi_cert_accreditor = covviCertAccreditor
                    newUserData.covvi_cert_date = covviCertDate
                  }
                  if (profile.role === "Distributor") {
                    newUserData.distributor = profile.uid
                  } else if (distributorEmail) {
                    newUserData.distributor = distributorList?.filter(
                      (distributor) => distributor.email_address === distributorEmail
                    )[0].uid
                  } else {
                    newUserData.distributor = ""
                  }
                }
                return newUserData as ProfileData
              }
            }
          }
        },
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      userData,
      businessName,
      contactNumber,
      addressLine1,
      addressLine2,
      city,
      county,
      stateProvinceTerritory,
      country,
      postCode,
      clinicianEmail,
      distributorEmail,
      role,
      isRestricted,
      firstName,
      lastName,
      covviCertAccreditor,
      covviCertDate,
    ]
  )

  useEffect(() => {
    if (
      profile &&
      ["Admin", "Customer Service Team Member", "Sales Team Member"].includes(profile.role) &&
      (section === "Add User" || section === "Edit User")
    ) {
      getMyUsers(profile.uid, profile.role).then((res) => {
        let clinicians = res.filter((profile) => profile.role === "Clinician")
        setClinicianList(clinicians)
        let distributors = res.filter((profile) => profile.role === "Distributor")
        setDistributorList(distributors)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  useEffect(() => {
    if (userData) {
      userData.role && setRole(userData.role)
      userData.first_name && setFirstName(userData.first_name)
      userData.last_name && setLastName(userData.last_name)
      userData.email_address && setEmailAddress(userData.email_address)
      userData.business_name && setBusinessName(userData.business_name)
      userData.contact_number && setContactNumber(userData.contact_number)
      userData.address_line_1 && setAddressLine1(userData.address_line_1)
      userData.address_line_2 && setAddressLine2(userData.address_line_2)
      userData.address_city && setCity(userData.address_city)
      userData.address_county && setCounty(userData.address_county)
      userData.address_state_province_territory &&
        setStateProvinceTerritory(userData.address_state_province_territory)
      userData.address_country && setCountry(userData.address_country)
      userData.address_postal_code && setPostCode(userData.address_postal_code)
      userData.emailVerified && setEmailVerified(userData.emailVerified)
      userData.completed && setHasResetPassword(userData.completed)
      userData.covvi_cert_accreditor && setCovviCertAccreditor(userData.covvi_cert_accreditor)
      userData.covvi_cert_date && setCovviCertDate(userData.covvi_cert_date)
      userData.isRestricted ? setIsRestricted(true) : setIsRestricted(false)
      userData.dataConsent && setHasDataConsent(true)
      const timestampOrObjectMaybe = userData.appLogins && userData.appLogins.slice(-1)[0]
      const timestamp =
        timestampOrObjectMaybe &&
        new firestore.Timestamp(timestampOrObjectMaybe.seconds, timestampOrObjectMaybe.nanoseconds)
      timestamp && setLastAppLogIn(timestamp.toDate())
      userData.clinician &&
        getProfile(userData.clinician).then((res) => setClinicianEmail(res.email_address))
      userData.distributor &&
        getProfile(userData.distributor).then((res) => setDistributorEmail(res.email_address))
    }
    // disabled check

    if (section === "Add User") {
      setDisabled(false)
    } else if (section === "Edit User") {
      if (
        ["Customer Service Team Member", "Admin"].includes(profile!.role) ||
        (profile?.associated_users && userData && profile?.associated_users.includes(userData.uid))
      )
        setDisabled(false)
    }
  }, [userData, profile])

  useEffect(() => {
    if (userData) {
      if (section === "Edit User") {
        if (profile?.role === "Admin" || profile?.role === "Customer Service Team Member") {
          const authDetails = getAuthDetails(userData.uid)
          const getInviterName = getProfile(userData.invited_by)

          Promise.all([authDetails, getInviterName])
            .then((res) => {
              setAccountCreated(res[0].creationTime)
              setAuthLastLogIn(res[0].lastSignInTime ? res[0].lastSignInTime : "None")
              setInvitedBy(res[1].first_name + " " + res[1].last_name)
            })
            .catch((e) => {
              profile?.role === "Admin" && console.log(e)
            })
        }
      }
    }
  }, [userData, profile, section])

  const [errors, setErrors] = useState<ErrorObj>({})

  useEffect(() => {
    const checkForChanges = async () => {
      let doesHaveChanges = false

      if (userData) {
        userData.address_city !== city && (doesHaveChanges = true)
        userData.address_country !== country && (doesHaveChanges = true)
        userData.address_county !== county && (doesHaveChanges = true)
        userData.address_line_1 !== addressLine1 && (doesHaveChanges = true)
        userData.address_line_2 !== addressLine2 && (doesHaveChanges = true)
        userData.address_postal_code !== postCode && (doesHaveChanges = true)
        userData.address_state_province_territory !== stateProvinceTerritory &&
          (doesHaveChanges = true)
        userData.business_name !== businessName && (doesHaveChanges = true)
        if (userData.clinician) {
          await getProfile(userData.clinician).then((res) => {
            clinicianEmail !== res.email_address && (doesHaveChanges = true)
          })
        } else {
          clinicianEmail && (doesHaveChanges = true)
        }
        if (userData.distributor) {
          await getProfile(userData.distributor).then((res) => {
            distributorEmail !== res.email_address && (doesHaveChanges = true)
          })
        } else {
          distributorEmail && (doesHaveChanges = true)
        }

        userData.contact_number !== contactNumber && (doesHaveChanges = true)
        if (!userData.covvi_cert_accreditor) {
          covviCertAccreditor !== "None" && (doesHaveChanges = true)
        }
        userData.first_name !== firstName && (doesHaveChanges = true)
        userData.isRestricted !== isRestricted && (doesHaveChanges = true)
        userData.last_name !== lastName && (doesHaveChanges = true)
        userData.role !== role && (doesHaveChanges = true)

        setHasChanges && setHasChanges(doesHaveChanges ? true : false)
      }
    }

    checkForChanges().catch(
      (e) => profile?.role === "Admin" && console.log(e, "error checking for changes")
    )
  }, [
    userData,
    city,
    country,
    county,
    addressLine1,
    addressLine2,
    postCode,
    stateProvinceTerritory,
    businessName,
    clinicianEmail,
    distributorEmail,
    contactNumber,
    covviCertAccreditor,
    firstName,
    lastName,
    isRestricted,
    role,
  ])

  const validateForm = () => {
    if (!profile) {
      return false
    }
    let formErrors: ErrorObj = {}
    if (role === "") formErrors.role = true
    if (firstName === "" || firstName.length < 2) formErrors.firstName = true
    if (lastName === "" || lastName.length < 2) formErrors.lastName = true
    if (!validator.isEmail(emailAddress)) formErrors.emailAddress = true
    if (
      role === "User" &&
      clinicianEmail &&
      !["Clinician", "Distributor"].includes(profile?.role) &&
      !clinicianList?.filter((clinician) => clinicianEmail === clinician.email_address).length
    ) {
      formErrors.clinicianEmail = true
    }
    if (
      role === "Clinician" &&
      distributorEmail &&
      profile?.role !== "Distributor" &&
      !distributorList?.filter((distributor) => distributorEmail === distributor.email_address)
        .length
    ) {
      formErrors.distributorEmail = true
    }
    if (role === "Clinician" || role === "Distributor") {
      if (businessName === "") formErrors.businessName = true
      if (contactNumber === "") formErrors.contactNumber = true
      if (addressLine1 === "") formErrors.addressLine1 = true
      if (city === "") formErrors.city = true
      if (country === "") formErrors.country = true
      if (postCode === "") formErrors.postCode = true
    }
    let isError = false
    for (const prop in formErrors) {
      if (formErrors[prop as keyof typeof formErrors] === true) {
        isError = true
      }
    }
    setErrors({ ...formErrors })
    return !isError
  }

  const handleSubmit = () => {
    if (section === "Account Management") {
      return
    } else {
      let isValid = validateForm()
      if (isValid === false) {
      } else {
        if (profile) {
          const newUserData: UserObj = {
            first_name: firstName,
            last_name: lastName,
            email_address: emailAddress,
            business_name: businessName,
            contact_number: contactNumber,
            address_line_1: addressLine1,
            address_line_2: addressLine2,
            address_city: city,
            address_county: county,
            address_state_province_territory: stateProvinceTerritory,
            address_country: country,
            address_postal_code: postCode,
            role,
            isRestricted: isRestricted,
          }
          if (role === "User" && clinicianEmail) {
            if (profile.role === "Clinician") {
              newUserData.clinician = profile.uid
            } else {
              newUserData.clinician = clinicianList?.filter(
                (clinician) => clinician.email_address === clinicianEmail
              )[0].uid
            }
          }
          if (role === "Clinician" && distributorEmail) {
            if (profile.role === "Distributor") {
              newUserData.distributor = profile.uid
            } else {
              newUserData.distributor = distributorList?.filter(
                (distributor) => distributor.email_address === distributorEmail
              )[0].uid
            }
          }
          onSubmit && onSubmit(newUserData as ProfileData)
        }
      }
    }
  }

  const getRoleOptions = () => {
    const index =
      profile!.role === "Admin" || section === "Account Management"
        ? ROLE_OPTIONS.indexOf(profile!.role)
        : ROLE_OPTIONS.indexOf(profile!.role) + 1
    return ROLE_OPTIONS.slice(index)
  }

  useEffect(() => {
    if (profile?.role === "Clinician" && section === "Add User") setRole("User")
  }, [profile, section])

  return (
    <>
      <div className="p-[30px]">
        <div className="flex flex-row flex-wrap gap-y-[20px] justify-between">
          <CVSubtitle text={ls.getText("User Details")} />
          <TextField
            className="basis-full"
            error={errors.role}
            helperText={errors.role ? ls.getText("Please Select a Role") : ""}
            variant="outlined"
            label={ls.getText("Role")}
            value={role}
            select
            onChange={(e) => setRole(e.target.value)}
            required={section !== "Account Management"}
            disabled={disabled || (profile?.role === "Clinician" && section === "Add User")}
          >
            <MenuItem disabled value="">
              {ls.getText("Select a User Role")}
            </MenuItem>
            {getRoleOptions().map((option, index) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className="basis-full sm:basis-[47%]"
            error={errors.firstName}
            helperText={errors.firstName ? ls.getText("Please enter a first name") : ""}
            variant="outlined"
            label={ls.getText("First Name")}
            value={firstName}
            disabled={disabled}
            required={section !== "Account Management"}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            className="basis-full sm:basis-[47%] self-end"
            error={errors.lastName}
            helperText={errors.lastName ? ls.getText("Please enter a last name") : ""}
            variant="outlined"
            label={ls.getText("Last Name")}
            value={lastName}
            disabled={disabled}
            required={section !== "Account Management"}
            onChange={(e) => setLastName(e.target.value)}
          />
          {section === "Add User" ? (
            <TextField
              className="basis-full"
              error={errors.emailAddress}
              helperText={
                errors.emailAddress ? ls.getText("Please enter a valid email address") : ""
              }
              variant="outlined"
              label={ls.getText("Email Address")}
              value={emailAddress}
              disabled={disabled}
              required
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          ) : (
            <TextField
              className="basis-full"
              label={ls.getText("Email Address")}
              value={emailAddress}
              disabled={true}
              id="email"
            />
          )}
          {role === "Clinician" &&
            distributorList &&
            (profile &&
            ["Admin", "Customer Service Team Member", "Sales Team Member"].includes(
              profile.role
            ) ? (
              <div className="basis-full">
                <Autocomplete
                  id="clinician"
                  disablePortal
                  sx={{ display: "flex", flexGrow: 1 }}
                  options={distributorList?.map((distributor) => distributor.email_address)}
                  onChange={(e, newValue) => {
                    setDistributorEmail(newValue ? newValue : "")
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={ls.getText("Distributor Email Address")}
                      onChange={(e) => {
                        setDistributorEmail(e.target.value)
                      }}
                      error={errors.distributorEmail}
                      helperText={errors.distributorEmail ? ls.getText("existing_distributor") : ""}
                    />
                  )}
                  clearOnBlur={false}
                  blurOnSelect
                  value={distributorEmail}
                  disabled={disabled}
                />
              </div>
            ) : (
              <>
                {distributorEmail && (
                  <TextField
                    className="basis-full sm:basis-[47%]"
                    label={ls.getText("Distributor")}
                    value={distributorEmail}
                    disabled={true}
                  />
                )}
              </>
            ))}
          {role === "User" &&
            clinicianList &&
            (profile &&
            ["Admin", "Customer Service Team Member", "Sales Team Member"].includes(
              profile.role
            ) ? (
              <>
                <Autocomplete
                  disablePortal
                  className="basis-full"
                  sx={{ display: "flex", flexGrow: 1 }}
                  options={clinicianList?.map((clinician) => clinician.email_address)}
                  onChange={(e, newValue) => {
                    setClinicianEmail(newValue ? newValue : "")
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={ls.getText("Clinician Email Address")}
                      onChange={(e) => {
                        setClinicianEmail(e.target.value)
                      }}
                      error={errors.clinicianEmail}
                      helperText={errors.clinicianEmail ? ls.getText("existing_clinician") : ""}
                    />
                  )}
                  clearOnBlur={false}
                  blurOnSelect
                  value={clinicianEmail}
                  disabled={disabled}
                />
              </>
            ) : (
              <>
                {clinicianEmail && (
                  <TextField
                    className="basis-full"
                    label={ls.getText("Clinician")}
                    value={clinicianEmail}
                    disabled={true}
                  />
                )}
              </>
            ))}
          {role === "User" && ["Add User", "Edit User"].includes(section) && (
            <div className="basis-full sm:basis-[47%] flex flex-row justify-between items-center">
              <FormControlLabel
                sx={{ color: "#747A87", fontWeight: "normal", fontFamily: "Proxima Nova" }}
                control={<Checkbox onChange={() => setIsRestricted(!isRestricted)} />}
                label={ls.getText("Restricted Mode")}
                checked={isRestricted}
                disabled={disabled}
              />
              <HelpTextTooltip
                text={
                  <>
                    <p>{`${ls.getText("restricted_description")}`}</p>
                    <br />
                    <p>{` ${ls.getText("restricted_modes_hidden")}: ${ls.getText(
                      "restricted_mode_info"
                    )}`}</p>
                  </>
                }
              />
            </div>
          )}
          {role === "Clinician" && section === "Edit User" && (
            <>
              <TextField
                className="basis-full sm:basis-[47%]"
                variant="outlined"
                label={ls.getText("COVVI Certification Date")}
                value={covviCertDate !== "None" ? stringDate(covviCertDate.seconds * 1000) : "None"}
                onClick={() => {
                  if (profile?.role === "Admin") {
                    setCovviCertAccreditor(profile!.uid)
                    setCovviCertDate(firestore.Timestamp.fromDate(new Date()))
                  }
                }}
                disabled={covviCertDate !== "None" || profile?.role !== "Admin"}
              />
              <TextField
                className="basis-full sm:basis-[47%]"
                variant="outlined"
                label={ls.getText("COVVI Certification Accreditor")}
                value={covviCertAccreditor}
                disabled={true}
              />
            </>
          )}
          {(role === "Clinician" || role === "Distributor") && (
            <>
              <div className="border-t border-[#EAEAEA] my-[30px] w-full" />
              <CVSubtitle text={ls.getText("Company Details")} />
              <TextField
                className="basis-full sm:basis-[47%]"
                error={errors.businessName}
                helperText={errors.businessName ? ls.getText("Please Enter a Company Name") : ""}
                variant="outlined"
                label={ls.getText("Company Name")}
                value={businessName}
                disabled={disabled}
                required={section !== "Account Management"}
                onChange={(e) => setBusinessName(e.target.value)}
              />
              <TextField
                className="basis-full sm:basis-[47%] self-end"
                error={errors.contactNumber}
                helperText={errors.contactNumber ? ls.getText("Please Enter a Contact Number") : ""}
                variant="outlined"
                label={ls.getText("Contact Number")}
                value={contactNumber}
                disabled={disabled}
                required={section !== "Account Management"}
                onChange={(e) => setContactNumber(e.target.value)}
              />
              <TextField
                className="basis-full"
                error={errors.addressLine1}
                helperText={
                  errors.addressLine1 ? ls.getText("Please Enter Your Company Address") : ""
                }
                variant="outlined"
                label={ls.getText("Company Address")}
                value={addressLine1}
                disabled={disabled}
                required={section !== "Account Management"}
                onChange={(e) => setAddressLine1(e.target.value)}
              />
              <TextField
                className="basis-full"
                variant="outlined"
                label={`${ls.getText("Company Address")} (${ls.getText("second line")})`}
                value={addressLine2}
                disabled={disabled}
                onChange={(e) => setAddressLine2(e.target.value)}
              />
              <TextField
                className="basis-full"
                error={errors.city}
                helperText={errors.city ? ls.getText("Please Enter Your City") : ""}
                variant="outlined"
                label={ls.getText("City")}
                value={city}
                disabled={disabled}
                required={section !== "Account Management"}
                onChange={(e) => setCity(e.target.value)}
              />
              <TextField
                className="basis-full sm:basis-[47%]"
                variant="outlined"
                label={ls.getText("County")}
                value={county}
                disabled={disabled}
                onChange={(e) => setCounty(e.target.value)}
              />
              <TextField
                className="basis-full sm:basis-[47%] self-end"
                variant="outlined"
                label={ls.getText("State/Province/Territory")}
                value={stateProvinceTerritory}
                disabled={disabled}
                onChange={(e) => setStateProvinceTerritory(e.target.value)}
              />
              <TextField
                className="basis-full sm:basis-[47%] self-end"
                error={errors.country}
                helperText={errors.country ? ls.getText("Please Enter Your Country") : ""}
                variant="outlined"
                label={ls.getText("Country")}
                value={country}
                disabled={disabled}
                required={section !== "Account Management"}
                onChange={(e) => setCountry(e.target.value)}
              />
              <TextField
                className="basis-full sm:basis-[47%]"
                error={errors.postCode}
                helperText={errors.postCode ? ls.getText("Please Enter Your Post Code") : ""}
                variant="outlined"
                label={ls.getText("Post Code")}
                value={postCode}
                disabled={disabled}
                required={section !== "Account Management"}
                onChange={(e) => setPostCode(e.target.value)}
              />
            </>
          )}
        </div>
        {section === "Edit User" && profile && isCovvi && (
          <div className="flex flex-col">
            <div className="border-t border-[#EAEAEA] my-[30px] w-full" />
            <CVSubtitle text={ls.getText("Account Checks")} />
            <div className="flex mt-3">
              <h2 className="text-semibold text-lg basis-full flex flex-row items-center">
                {emailVerified ? (
                  <CheckCircle className={"pb-1"} color="primary" />
                ) : (
                  <RadioButtonUnchecked className={"pb-1"} color="disabled" />
                )}
                {ls.getText("Email Verified")}
              </h2>
            </div>
            <div className="basis-full sm:basis-[47%]">
              <h2 className="text-semibold text-lg basis-full flex flex-row items-center">
                {hasResetPassword ? (
                  <CheckCircle className={"pb-1"} color="primary" />
                ) : (
                  <RadioButtonUnchecked className={"pb-1"} color="disabled" />
                )}
                {ls.getText("Password Reset")}
              </h2>
            </div>
            <div className="basis-full sm:basis-[47%]">
              <h2 className="text-semibold text-lg basis-full flex flex-row items-center">
                {hasDataConsent ? (
                  <CheckCircle className={"pb-1"} color="primary" />
                ) : (
                  <RadioButtonUnchecked className={"pb-1"} color="disabled" />
                )}
                {ls.getText("Data Collection Consent")}
              </h2>
            </div>
          </div>
        )}
        {profile &&
          userData &&
          ((["Admin", "Customer Service Team Member"].includes(profile.role) &&
            ["Distributor", "Clinician", "User"].includes(userData?.role)) ||
            (profile.role === "Clinician" && userData?.role === "User")) && (
            <>
              {profile &&
                ["Admin", "Customer Service Team Member", "Sales Team Member"].includes(
                  profile?.role
                ) &&
                section === "Edit User" && (
                  <>
                    <div className="border-t border-[#EAEAEA] my-[30px] w-full" />
                    <CVSubtitle text={ls.getText("Additional Account Info")} />
                    <div className="flex flex-row flex-wrap gap-y-[20px] justify-between mt-[16px]">
                      <TextField
                        className="basis-full sm:basis-[47%]"
                        label={ls.getText("Invited By")}
                        value={invitedBy}
                        disabled={true}
                      />
                      <TextField
                        className="basis-full sm:basis-[47%]"
                        label={ls.getText("Account Created")}
                        value={accountCreated !== undefined ? accountCreated : ""}
                        disabled={true}
                      />
                      <TextField
                        className="basis-full sm:basis-[47%]"
                        label={ls.getText("Last Log In")}
                        value={lastAuthLogIn}
                        disabled={true}
                      />
                      <TextField
                        className="basis-full sm:basis-[47%]"
                        label={ls.getText("Last App Log In")}
                        value={lastAppLogIn}
                        disabled={true}
                      />
                    </div>
                  </>
                )}
            </>
          )}
      </div>

      {additionalBtns && primaryLoading !== undefined && section === "Add User" && (
        <CVCardFooter>
          <>
            <ButtonRow
              buttonArray={[
                {
                  title: `${ls.getText("Add New")} ${role}`,
                  onClick: handleSubmit,
                  loading: primaryLoading,
                  variant: "contained",
                  disabled: !role,
                },
                ...additionalBtns,
              ]}
            />
          </>
        </CVCardFooter>
      )}
    </>
  )
})

export default UserCard
